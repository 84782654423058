<template>
  <div
    class="ma-0 row-bg-color custom-container-height"
    v-if="$store.getters.getWMSSHIPPINGTableProperties"
  >
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="$store.getters.getWMSSHIPPINGTableProperties"
      />
      <div
        class="min-w-250px max-w-500px pl-4 mt-8 font-weight-medium w-100 text-capitalize"
      >
        <v-text-field
          v-model="order_details_no"
          label="Scan Order Delivery No."
          type="text"
          autofocus
          outlined
          clearable
          class="poppins"
          append-icon="mdi-barcode-scan"
          @click:append="handleOrderScan"
          @keyup.enter="handleOrderScan"
          :disabled="!selectedWarehouse"
        />
      </div>
      <DataTable
        v-if="selectedWarehouse"
        :updateTableContent="updateTableContent"
        :toggleConfirmShippingModal="toggleConfirmShippingModal"
      />
      <ConfirmShippingModal
        ref="confirm_shipping"
        :confirmShipping="confirmShipping"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import DataTable from "@/own/components/warehouseManagement/shipping/DataTable.vue";
import ConfirmShippingModal from "@/own/components/warehouseManagement/shipping/ConfirmShippingModal.vue";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  UPDATE_CONFIRM_ORDER_DATA,
} from "@/core/services/store/shipping_wms.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "Inventory",
  components: {
    DatatableDetail,
    DataTable,
    ConfirmShippingModal,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
    },
    store_names: {
      getTableFilters: "getWMSSHIPPINGTableFilters",
      getTableState: "getWMSSHIPPINGTableState",
      getTableData: "getWMSSHIPPINGTableData",
      getTableHeaders: "getWMSSHIPPINGTableHeaders",
      getTableProperties: "getWMSSHIPPINGTableProperties",
      getTableExportUrl: "getWMSSHIPPINGExportUrl",
      getTablePermissions: "getWMSSHIPPINGTablePermissions",
    },
    order_details_no: null,
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    handleOrderScan() {
      this.toggleConfirmShippingModal({
        order_delivery_number: this.order_details_no,
      });
    },
    alertOnNoWarehouseSelected() {
      Swal.fire({
        title: `No warehouse selected`,
        icon: "warning",
        text: `Please select an warehouse to proceed further.`,
      });
    },
    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getWMSSHIPPINGTableState,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleConfirmShippingModal(item) {
      this.$store.dispatch(UPDATE_CONFIRM_ORDER_DATA, {
        warehouse_id: this.selectedWarehouse,
        order_delivery_number: item.order_delivery_number,
      });
      this.$refs.confirm_shipping.toggleModal();
    },
    confirmShipping() {
      this.$store.commit(SET_PAGE_LOADING, true);
      const data = {
        warehouse_id: this.selectedWarehouse,
        order_delivery_number:
          this.$store.getters.getWMSSHIPPINGConfirmOrderData.order
            .order_delivery_number,
      };

      ApiService.post("/warehouse_management/shipping/update", data)
        .then(() => {
          Swal.fire({
            title: "Update",
            text: `Shiping has been created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.updateTableContent();
          this.order_details_no = null;
          this.$refs.confirm_shipping.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  beforeMount() {
    if (!this.$store.getters.getSelectedWarehouse) {
      setTimeout(() => {
        this.alertOnNoWarehouseSelected();
      }, 400);
    }
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>

<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
