<template>
  <div>
    <v-card>
      <v-card-title
        tag="h1"
        class="text--secondary pb-0 ml-5 font-weight-medium font-size-h3 pt-9"
      >
        Packable Quantities
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <packing-tasks-table />
          </v-col>
        </v-row>
        <div class="position-relative">
          <div class="d-flex justify-content-center">
            <div class="zindex-4 bg-white px-8">
              <button
                class="btn btn--export-filter btn-sm py-3 mt-5 z-"
                @click="$refs.box_details.toggleModal()"
              >
                <span class="svg-icon mr-0 pr-0">
                  <v-icon size="20">mdi-package-variant</v-icon>
                </span>
                Add Box
              </button>
            </div>
          </div>
          <hr class="position-absolute w-100 divider" />
        </div>
        <!-- summary table start -->
        <v-data-table
          dense
          item-key="id"
          type="number"
          :headers="headers"
          :items="packingSummary"
          class="my-6 pl-4 pr-8"
          hide-default-footer
          mobile-breakpoint="300"
        >
          <template v-slot:top>
            <v-card-title
              tag="h1"
              class="text--secondary pb-0 ml-0 pl-0 font-weight-medium font-size-h3 pb-5"
            >
              Packing summary
            </v-card-title>
          </template>
        </v-data-table>
        <!-- summary table end -->
        <!--begin::Actions-->
        <div class="py-6 d-flex flex-grow-1 align-end justify-center">
          <button
            @click="submitPacking"
            type="submit"
            class="btn btn-info px-9 py-3 ls1"
          >
            Save
          </button>
        </div>
        <!--end::Actions-->
      </v-card-text>
    </v-card>
    <component ref="box_details" v-bind:is="boxDetailsModal"></component>
    <component
      ref="invoice_modal"
      :refresher="refresher"
      v-bind:is="invoiceModal"
    ></component>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { CONFIRM_BATCH_PACKING } from "@/core/services/store/packing2.module";
import PackingTasksTable from "@/own/components/warehouseManagement/packing/PackingTasksTable.vue";
import BoxDetailsModal from "@/own/components/warehouseManagement/packing/BoxDetailsModal.vue";
import InvoiceModal from "@/own/components/warehouseManagement/packing/InvoiceModal.vue";

export default {
  name: "BatchPacking",
  props: ["refresher"],
  components: { PackingTasksTable, BoxDetailsModal },
  data: () => ({
    boxDetailsModal: BoxDetailsModal,
    invoiceModal: InvoiceModal,
    headers: [
      {
        text: "Box",
        align: "start",
        sortable: false,
        value: "box_no",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "SKUS",
        align: "start",
        sortable: false,
        value: "skus",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Totes",
        align: "start",
        sortable: false,
        value: "tote_id",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Quantity",
        align: "start",
        sortable: false,
        value: "packed_qty",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
    ],
  }),
  methods: {
    submitPacking() {
      if (this.boxItems.length) {
        if (this.isAllItemsPacked) {
          this.$store.commit(SET_PAGE_LOADING, true);
          const payload = {
            boxes: [...this.boxItems],
          };
          this.$store.dispatch(CONFIRM_BATCH_PACKING, payload).finally(() => {
            this.$refs.invoice_modal.toggleModal();
          });
        } else {
          Swal.fire({
            title: `Invalid packing`,
            text: `Partial packing not allowed`,
            icon: "warning",
            confirmButtonClass: "btn btn-primary",
            showConfirmButton: true,
            // timer: 2500,
          });
          return;
        }
      } else {
        Swal.fire({
          title: `No packing`,
          text: `Select some packing and try again`,
          icon: "warning",
          confirmButtonClass: "btn btn-primary",
          showConfirmButton: true,
          // timer: 2500,
        });
      }
    },
  },
  computed: {
    boxItems: function () {
      return this.$store.getters.getWMSPACKINGBatchPackingBox;
    },
    packedItems: function () {
      return this.$store.getters.getWMSPACKINGBatchPackingBox;
    },
    packingSummary: function () {
      return this.packedItems.map((item, index) => ({
        box_no: index + 1,
        skus: item.skus.map((item) => item.sku),
        tote_id: item.skus.map((item) => item.tote_id),
        packed_qty: item.skus.map((item) => item.packed_qty),
      }));
    },
    skuTasks: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
    isAllItemsPacked: function () {
      return this.skuTasks.every((item) => item.qty === 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.divider {
  bottom: 10px;
  background-color: gainsboro;
}
</style>
