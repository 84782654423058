<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Packing details</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-575px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <div>
          <v-text-field
            ref="sku"
            v-model="sku"
            label="Scan SKU"
            type="text"
            autofocus
            outlined
            dense
            clearable
            class="poppins rounded-lg"
            append-icon="mdi-barcode-scan"
            @click:append="enterSku"
            @keyup.enter="enterSku"
          />
        </div>
        <div>
          <v-text-field
            ref="quantity"
            v-model="qty"
            label="Quantity"
            type="number"
            outlined
            dense
            clearable
            class="poppins rounded-lg"
            append-icon="mdi-barcode-scan"
            @click:append="enterQty"
            @keyup.enter="enterQty"
            :disabled="!this.skuEntered.item || !sku"
          />
        </div>
        <packing-tasks-table :custom-items="selectedItems" />
        <v-row class="pt-md-10">
          <v-col cols="6">
            <v-text-field
              v-model="formData.weight"
              label="Weigth (KG)"
              outlined
              dense
              type="number"
              clearable
              class="poppins"
              @input="$v.formData.weight.$touch()"
              @blur="$v.formData.weight.$touch()"
              @keyup.enter="$refs.height.focus()"
              :error-messages="weightErrors"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="volume"
              label="Volume"
              outlined
              clearable
              type="number"
              dense
              class="poppins"
              disabled
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="height"
              v-model="formData.height"
              label="Height"
              outlined
              type="number"
              dense
              clearable
              class="poppins"
              @keyup.enter="$refs.width.focus()"
              @input="$v.formData.height.$touch()"
              @blur="$v.formData.height.$touch()"
              :error-messages="heightErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="width"
              v-model="formData.width"
              label="Width"
              outlined
              type="number"
              dense
              clearable
              class="poppins"
              @input="$v.formData.width.$touch()"
              @blur="$v.formData.width.$touch()"
              @keyup.enter="$refs.length.focus()"
              :error-messages="widthErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="length"
              v-model="formData.length"
              label="Length"
              outlined
              type="number"
              dense
              clearable
              class="poppins"
              @input="$v.formData.length.$touch()"
              @blur="$v.formData.length.$touch()"
              @keyup.enter="$refs.submit.focus()"
              :error-messages="lengthErrors"
            />
          </v-col>
        </v-row>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="py-6 d-flex flex-grow-1 align-end justify-center">
          <button
            ref="submit"
            @click="submit"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Save
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import PackingTasksTable from "@/own/components/warehouseManagement/packing/PackingTasksTable.vue";
import {
  SET_BATCH_PACKING_BOX,
  UPDATE_SKU_TASK_QTY,
  SET_VIRTUAL_SKU_TAKS,
  UPDATE_VIRTUAL_SKU_TASK_QTY,
} from "@/core/services/store/packing2.module";

export default {
  name: "BoxDetailsModal",
  mixins: [validationMixin],
  validations: {
    formData: {
      weight: { required },
      height: { required },
      width: { required },
      length: { required },
    },
  },
  components: { PackingTasksTable },
  data: () => ({
    dialog: false,
    sku: null,
    qty: null,
    skuEntered: {
      item: null,
      index: null,
    },
    selectedItems: [
      /****************
      {
        tote_id
        sku.text1
        qty
      }
       ****************/
    ],
    formData: {
      weight: null,
      height: null,
      width: null,
      length: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.formData = {
          weight: null,
          height: null,
          width: null,
          length: null,
        };
        this.selectedItems = [];
        this.sku = null;
        this.$v.$reset();
      } else {
        this.$store.commit(
          SET_VIRTUAL_SKU_TAKS,
          JSON.parse(JSON.stringify(this.skuItems))
        );
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    enterSku() {
      this.skuEntered.item = this.virtualSkuItems.find(
        (item) => item.sku.text1 === this.sku && item.qty > 0
      );
      if (!this.skuEntered.item) {
        Swal.fire({
          title: `Invalid SKU`,
          text: `SKU doesn't exists`,
          icon: "warning",
          showConfirmButton: true,
          focusConfirm: true,
          // timer: 2500,
        });
        return;
      }
      setTimeout(() => {
        this.$refs.quantity.focus();
      }, 100);
    },
    enterQty() {
      if (this.qty) {
        const item = this.skuEntered.item;
        this.skuEntered.index = this.selectedItems.findIndex(
          (element) =>
            element.sku.text1 === item.sku.text1 &&
            element.tote_id === item.tote_id
        );

        if (this.skuEntered.index === -1) {
          if (this.qty > item.qty) {
            Swal.fire({
              title: `Quantity exceed`,
              text: `Quantity limit exceed for this SKU`,
              icon: "warning",
              showConfirmButton: true,
              // timer: 2500,
            });
            this.qty = null;
          } else {
            this.$store.commit(UPDATE_VIRTUAL_SKU_TASK_QTY, {
              ...item,
              qty: this.qty,
            });
            this.selectedItems.push({ ...item, qty: this.qty });
            this.skuEntered = {
              item: null,
              index: null,
            };
            this.sku = null;
            this.qty = null;
            this.$refs.sku.focus();
          }
        } else {
          const qty = this.selectedItems[this.skuEntered.index].qty;
          const index = this.virtualSkuItems.findIndex(
            (item) =>
              item.sku.text1 === this.sku &&
              item.tote_id === this.skuEntered.item.tote_id
          );
          if (this.qty <= this.virtualSkuItems[index].qty) {
            this.$store.commit(UPDATE_VIRTUAL_SKU_TASK_QTY, {
              ...item,
              qty: this.qty,
            });
            this.selectedItems[this.skuEntered.index].qty =
              Number(qty) + Number(this.qty);
            this.skuEntered = {
              item: null,
              index: null,
            };
            this.sku = null;
            this.qty = null;
            this.$refs.sku.focus();
          } else {
            Swal.fire({
              title: `Quantity exceed`,
              text: `Quantity limit exceed for this SKU`,
              icon: "warning",
              showConfirmButton: true,
              // timer: 2500,
            });
            this.qty = null;
          }
        }
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.selectedItems.length) {
          const skus = this.selectedItems.map((item) => ({
            id: item.id,
            tote_id: item.tote_id,
            sku: item.sku.text1,
            packed_qty: item.qty,
          }));
          const details = {
            ...this.formData,
            volume: this.volume,
          };
          this.$store.commit(SET_BATCH_PACKING_BOX, { skus, details });
          this.toggleModal();
          //set status for packing tasks table
          skus.map((item) => {
            this.$store.commit(UPDATE_SKU_TASK_QTY, item);
          });
        } else {
          Swal.fire({
            title: `NO SKU selected`,
            text: `No SKU has been added`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
        }
      }
    },
  },
  computed: {
    skuItems: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
    virtualSkuItems: function () {
      return this.$store.getters.getWMSPACKINGVirtualSKUTasks;
    },
    volume: function () {
      const { height, width, length } = this.formData;
      return height * width * length;
    },
    weightErrors() {
      return this.handleFormValidation("weight", this);
    },
    heightErrors() {
      return this.handleFormValidation("height", this);
    },
    widthErrors() {
      return this.handleFormValidation("width", this);
    },
    lengthErrors() {
      return this.handleFormValidation("length", this);
    },
  },
  mounted() {},
};
</script>
