var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"bg-white poppins"},[_c('div',{staticClass:"modal-header align-center"},[_c('h3',{staticClass:"mb-0 font-weight-bolder"},[_vm._v("Printing details")]),_c('div',{staticClass:"btn btn-sm btn-icon btn-active-light-info",on:{"click":_vm.toggleModal}},[_c('span',{staticClass:"svg-icon"},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-close")])],1)])]),_c('div',{staticClass:"modal-body d-flex flex-column h-575px scroll-y mx-5 mx-xl-15 py-7"},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.packingResponse.message))]),_c('div',{class:[
          'row my-5',
          { 'justify-content-center': !_vm.packingResponse.success },
        ]},_vm._l((_vm.types),function(print,index){return _c('div',{key:index,class:[
            { 'col-4 col-md-3': print.show },
            { 'd-none': !print.show },
          ],on:{"click":() => _vm.onPrint(print.value)}},[(print.show)?_c('button',{staticClass:"btn btn-block btn--export-filter"},[_vm._v(" "+_vm._s(print.name)+" ")]):_vm._e()])}),0),_c('hr',{staticClass:"my-10"}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn--print-upload",on:{"click":_vm.toggleModal}},[_vm._v(" Done ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }