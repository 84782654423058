<template>
  <div>
    <v-card>
      <v-card-title
        tag="h1"
        class="text--secondary pb-0 ml-5 font-weight-medium font-size-h3 pt-9"
      >
        Packing tasks
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <packing-tasks-table />
          </v-col>
        </v-row>
        <hr />
        <v-row class="pt-md-10">
          <v-col cols="6">
            <v-text-field
              v-model="formData.weight"
              label="Weigth (KG)"
              outlined
              dense
              type="number"
              clearable
              class="poppins"
              @input="$v.formData.weight.$touch()"
              @blur="$v.formData.weight.$touch()"
              @keyup.enter="$refs.height.focus()"
              :error-messages="weightErrors"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="volume"
              label="Volume"
              outlined
              clearable
              type="number"
              dense
              class="poppins"
              disabled
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="height"
              v-model="formData.height"
              label="Height"
              outlined
              type="number"
              dense
              clearable
              class="poppins"
              @input="$v.formData.height.$touch()"
              @blur="$v.formData.height.$touch()"
              @keyup.enter="$refs.width.focus()"
              :error-messages="heightErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="width"
              v-model="formData.width"
              label="Width"
              outlined
              type="number"
              dense
              clearable
              class="poppins"
              @input="$v.formData.width.$touch()"
              @blur="$v.formData.width.$touch()"
              @keyup.enter="$refs.length.focus()"
              :error-messages="widthErrors"
            />
          </v-col>
          <v-col cols="6" md="4" class="pt-0">
            <v-text-field
              ref="length"
              v-model="formData.length"
              label="Length"
              outlined
              type="number"
              dense
              clearable
              class="poppins"
              @input="$v.formData.length.$touch()"
              @blur="$v.formData.length.$touch()"
              @keyup.enter="$refs.submit.focus()"
              :error-messages="lengthErrors"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center">
          <button
            ref="submit"
            class="btn--export-filter p-3 rounded-lg mt-2 mb-4"
            @click="submit"
          >
            Submit
          </button>
        </div>
      </v-card-text>
    </v-card>
    <component
      ref="invoice_modal"
      :refresher="refresher"
      v-bind:is="invoiceModal"
    ></component>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { CONFIRM_SINGLE_PACKING } from "@/core/services/store/packing2.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import PackingTasksTable from "@/own/components/warehouseManagement/packing/PackingTasksTable.vue";
import InvoiceModal from "@/own/components/warehouseManagement/packing/InvoiceModal.vue";

export default {
  name: "SinglePacking",
  mixins: [validationMixin],
  props: ["refresher"],
  validations: {
    formData: {
      weight: { required },
      height: { required },
      width: { required },
      length: { required },
    },
  },
  components: { PackingTasksTable },
  data: () => ({
    invoiceModal: InvoiceModal,
    formData: {
      weight: null,
      height: null,
      width: null,
      length: null,
    },
  }),
  methods: {
    secondText() {
      return "second-text";
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.commit(SET_PAGE_LOADING, true);

        const { height, weight, width, length } = this.formData;
        const skus = this.skuTasks.map((item) => ({
          id: item.id,
          tote_id: item.tote_id,
          sku: item.sku.text1,
          packed_qty: item.qty,
        }));
        const submitPayload = {
          boxes: [
            {
              skus,
              details: {
                height,
                weight,
                width,
                length,
              },
            },
          ],
        };
        this.$store
          .dispatch(CONFIRM_SINGLE_PACKING, submitPayload)
          .finally(() => {
            this.$refs.invoice_modal.toggleModal();
          });
      }
    },
  },
  computed: {
    volume: function () {
      const { height, width, length } = this.formData;
      return height * width * length;
    },
    weightErrors() {
      return this.handleFormValidation("weight", this);
    },
    heightErrors() {
      return this.handleFormValidation("height", this);
    },
    widthErrors() {
      return this.handleFormValidation("width", this);
    },
    lengthErrors() {
      return this.handleFormValidation("length", this);
    },
    skuTasks() {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
  },
};
</script>
