<template>
  <v-data-table
    dense
    type="number"
    :headers="headers"
    :items="customItems || items"
    item-key="tote_id"
    class="my-6 pl-4 pr-8"
    hide-default-footer
    mobile-breakpoint="300"
  >
  </v-data-table>
</template>

<script>
export default {
  name: "PackingTasksTable",
  props: ["customItems"],
  computed: {
    headers: function () {
      return [
        {
          text: "Tote",
          align: "start",
          sortable: false,
          value: "tote_id",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "sku.text1",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Quantity",
          align: "start",
          sortable: false,
          value: "qty",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "wms_status_label",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
      ];
    },
    items: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
  },
};
</script>
